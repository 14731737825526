<div class="box box1">
    <div class="homeContent">
        <h1 style="text-align: center;">Mutatók</h1>
        <p align="center">
            <span style="font-family: Times New Roman,serif;">
                <span style="font-size: large;">
                    <b>Vizsga Sikerességi Mutató (VSM)</b>
                </span>
            </span>
        </p>
        <p align="center">
            <span style="font-family: Times New Roman,serif;">2024. 4. negyedév (%-ban)</span>
        </p>
        <table width="100%" cellspacing="0" cellpadding="7">
            <tbody>
                <tr>
                    <td colspan="2" width="25%">
                        <p align="center"><span style="font-family: Times New Roman,serif;"><b>Képző szerv</b></span>
                        </p>
                    </td>
                    <td colspan="2" width="50%">
                        <p align="center"><span style="font-family: Times New Roman,serif;"><b>A1, A2, A
                                    összesítve</b></span></p>
                    </td>
                    <td colspan="2" width="25%">
                        <p align="center"><span style="font-family: Times New Roman,serif;"><b>B</b></span></p>
                    </td>
                </tr>
                <tr>
                    <td colspan="1" rowspan="1">
                        <p align="center"><span style="font-family: Times New Roman,serif;">Név</span></p>
                    </td>
                    <td colspan="1" rowspan="1">
                        <p align="center"><span style="font-family: Times New Roman,serif;">Azon</span></p>
                    </td>
                    <td colspan="1">
                        <p align="center"><span style="font-family: Times New Roman,serif;"><b>Elmélet</b></span></p>
                    </td>
                    <td colspan="1">
                        <p align="center"><span style="font-family: Times New Roman,serif;"><b>Forgalom</b></span></p>
                    </td>
                    <td colspan="1">
                        <p align="center"><span style="font-family: Times New Roman,serif;"><b>Elmélet</b></span></p>
                    </td>
                    <td colspan="1">
                        <p align="center"><span style="font-family: Times New Roman,serif;"><b>Forgalom</b></span></p>
                    </td>
                </tr>
                <tr>
                    <td colspan="1">
                        <p align="center">
                            <span style="font-family: Times New Roman,serif;">
                                <span style="font-size: medium;">
                                    <b>Rózsa Autósiskola</b>
                                </span>
                            </span>
                        </p>
                    </td>
                    <td colspan="1">
                        <p align="center"><span style="font-family: Times New Roman,serif;"><span
                                    style="font-size: medium;">3184</span></span></p>
                    </td>
                    <td colspan="1">
                        <p align="center"><span style="font-family: Times New Roman,serif;">-</span></p>
                    </td>
                    <td colspan="1">
                        <p align="center"><span style="font-family: Times New Roman,serif;">-</span></p>
                    </td>
                    <td colspan="1">
                        <p align="center"><span style="font-family: Times New Roman,serif;">29,41%</span></p>
                    </td>
                    <td colspan="1">
                        <p align="center"><span style="font-family: Times New Roman,serif;">53,85%</span></p>
                    </td>
                </tr>
            </tbody>
        </table>

        <p align="center">
            <span style="font-family: Times New Roman,serif;">
                <span style="font-size: large;">
                    <b>Átlagos Képzési Óraszám (ÁKÓ)</b>
                </span>
            </span>
        </p>
        <p align="center">
            <span style="font-family: Times New Roman,serif;">2024. 4. negyedév (%-ban)</span>
        </p>
        <table width="100%" cellspacing="0" cellpadding="7">
            <tbody>
                <tr>
                    <td colspan="2" width="25%">
                        <p align="center"><span style="font-family: Times New Roman,serif;"><b>Képző szerv</b></span>
                        </p>
                    </td>
                    <td colspan="1" width="50%">
                        <p align="center"><span style="font-family: Times New Roman,serif;"><b>A1, A2, A
                                    összesítve</b></span></p>
                    </td>
                    <td colspan="1" width="25%">
                        <p align="center"><span style="font-family: Times New Roman,serif;"><b>B</b></span></p>
                    </td>
                </tr>
                <tr>
                    <td colspan="1" rowspan="1">
                        <p align="center"><span style="font-family: Times New Roman,serif;">Név</span></p>
                    </td>
                    <td colspan="1" rowspan="1">
                        <p align="center"><span style="font-family: Times New Roman,serif;">Azon</span></p>
                    </td>
                    <td colspan="1">
                        <p align="center"><span style="font-family: Times New Roman,serif;"><b>Forgalom</b></span></p>
                    </td>
                    <td colspan="1">
                        <p align="center"><span style="font-family: Times New Roman,serif;"><b>Forgalom</b></span></p>
                    </td>
                </tr>
                <tr>
                    <td colspan="1">
                        <p align="center">
                            <span style="font-family: Times New Roman,serif;">
                                <span style="font-size: medium;">
                                    <b>Rózsa Autósiskola</b>
                                </span>
                            </span>
                        </p>
                    </td>
                    <td colspan="1">
                        <p align="center"><span style="font-family: Times New Roman,serif;"><span
                                    style="font-size: medium;">3184</span></span></p>
                    </td>
                    <td colspan="1">
                        <p align="center"><span style="font-family: Times New Roman,serif;">-</span></p>
                    </td>
                    <td colspan="1">
                        <p align="center"><span style="font-family: Times New Roman,serif;">151,23%</span></p>
                    </td>
                </tr>
            </tbody>
        </table>

        <p align="center">
            <span style="font-family: Times New Roman,serif;">
                <span style="font-size: large;">
                    <b>Képzési Költség (KK)</b>
                </span>
            </span>
        </p>
        <p align="center">
            <span style="font-family: Times New Roman,serif;">2024. 4. negyedév (%-ban)</span>
        </p>
        <table width="100%" cellspacing="0" cellpadding="7">
            <tbody>
                <tr>
                    <td colspan="2" width="28%">
                        <p align="center"><span style="font-family: Times New Roman,serif;"><b>Képző szerv</b></span>
                        </p>
                    </td>
                    <td colspan="1" rowspan="2" width="18%">
                        <p align="center"><span style="font-family: Times New Roman,serif;"><b>A</b></span></p>
                    </td>
                    <td colspan="1" rowspan="2" width="18%">
                        <p align="center"><span style="font-family: Times New Roman,serif;"><b>A1</b></span></p>
                    </td>
                    <td colspan="1" rowspan="2" width="18%">
                        <p align="center"><span style="font-family: Times New Roman,serif;"><b>B</b></span></p>
                    </td>
                </tr>
                <tr>
                    <td colspan="1" rowspan="1">
                        <p align="center"><span style="font-family: Times New Roman,serif;">Név</span></p>
                    </td>
                    <td colspan="1" rowspan="1">
                        <p align="center"><span style="font-family: Times New Roman,serif;">Azon</span></p>
                    </td>
                </tr>
                <tr>
                    <td colspan="1">
                        <p align="center">
                            <span style="font-family: Times New Roman,serif;">
                                <span style="font-size: medium;">
                                    <b>Rózsa Autósiskola</b>
                                </span>
                            </span>
                        </p>
                    </td>
                    <td colspan="1">
                        <p align="center"><span style="font-family: Times New Roman,serif;"><span
                                    style="font-size: medium;">3184</span></span></p>
                    </td>
                    <td colspan="1">
                        <p align="center"><span style="font-family: Times New Roman,serif;">305.000 Ft</span></p>
                    </td>
                    <td colspan="1">
                        <p align="center"><span style="font-family: Times New Roman,serif;">205.000 Ft</span></p>
                    </td>
                    <td colspan="1">
                        <p align="center"><span style="font-family: Times New Roman,serif;">275.000 Ft</span></p>
                    </td>
                </tr>
            </tbody>
        </table>

        <h1 style="text-align: center;">Egyéb</h1>
        <p>OKÉV szám: 04-0055-04</p>
        <p>Felnőttképzési nyilvántartási szám: B/2020/009192</p>
        <p>Írásos Tájékoztató: <a href="assets/irtajek.pdf">Megtekint</a></p>
        <p>Információs önrendelkezési jogról és az információszabadságról: <a href="assets/informaciosJog.pdf">Megtekint</a></p>
        <p>Felnőttképzési Szerződés: <a href="assets/felnottkepzszerz.pdf">Megtekint</a></p>
    </div>
    <div class="footer"></div>
</div>